@import 'reset';
@import 'scrollbar';
@import 'pygments';
@import 'animations';

@font-face {
    font-family: Abaddon;
    src: url("/assets/fonts/Abaddon Light.ttf");
    font-display: swap;
}

@font-face {
    font-family: Abaddon;
    font-weight: bold;
    src: url("/assets/fonts/Abaddon Bold.ttf");
    font-display: swap;
}

$bg-color: #1a171c;

* {
    font-display: swap;
}

html,
body {
    background-color: $bg-color;
    color: white;
    font-size: 22px;
    font-family: "Abaddon", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 3rem 0 2rem;
    font-weight: 400;
    line-height: 1.3;
    text-align: center;
}

h1 {
    margin-top: 0;
    font-size: 3.052rem;
    font-weight: 600;
}

h2 {
    font-size: 2.441rem;
}

h3 {
    font-size: 1.953rem;
}

h4 {
    font-size: 1.563rem;
}

h5 {
    font-size: 1.25rem;
}

small,
.text_small {
    font-size: 0.9rem;
}

a {
    color: rgb(226, 182, 255);
    transition: all 100ms ease-in-out;
}

a:visited,
a:hover {
    color: rgb(255, 128, 251);
}

dd {
    margin-left: 1rem;
}

pre {
    padding: 1rem;
    font-size: 16px;
    font-family: monospace;
    overflow: auto;
    border-radius: 8px;
    overflow: hidden;

    &.highlight {
        background-color: #252228;
    }
}

div#particles-js {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}

#navbar {
    display: flex;
    background: darken($bg-color, 10%);
    position: relative;
    top: 0;
    justify-content: center;
    z-index: 10;
    height: 35rem;

    .nav-center {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .nav-center {
        flex: 1 1 auto;
        max-width: 75vw;
        min-width: 300px;
        justify-content: center;

        a.logo-wrapper {
            display: flex;
            align-items: center;
            text-decoration: none;
            color: white;

            .title {
                font-size: 3.75rem;
                font-weight: 600;
            }
    
            .logo {
                width: 8rem;
                height: 8rem;
                image-rendering: pixelated;
            }

            &:hover {
                .logo {
                    animation: bounce-in 2s ease 1;
                    animation-fill-mode: forwards;
                }
            }
        }
    }

    .nav-links {
        margin-left: auto;
        justify-self: flex-end;
        align-items: center;
        padding: 0;
        display: flex;
        list-style: none;
        height: 100%;

        a {
            padding: 1em;
            display: inline-block;
            height: 100%;
            text-decoration: none;
            font-size: 1.5rem;
            font-weight: bold;
            background-color: transparent;
            transition: all 200ms ease-in-out;

            &:hover {
                background: rgb(226, 182, 255);
                color: #2A2A2A;
            }
        }
    }
}

.wrapper {
    padding: 2rem;
    max-width: 900px;
    text-align: center;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}

div#projects {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(285px, 1fr));
    grid-auto-flow: dense;
    grid-gap: 2rem;

    .project-card {
        text-align: left;
        overflow: hidden;
        height: 100%;

        .project-details {
            h3 {
                text-align: left;
                margin-top: 0;
                margin-bottom: 0;
            }

            small {
                margin-bottom: 1rem;
                display: block;
            }
        }

        a {
            text-decoration: none;
            color: white;
            position: relative;
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }

    .project-details {
        padding: 5%;
        background: #302934;
        flex: 1 1 auto;
    }

    .project-image {
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

}

.sms {
	display: flex;
	a {
		display: inline-block;
		padding: 0.25rem;
		filter: brightness(1.5) grayscale(1);
		opacity: 0.8;
		transition: all 100ms ease-out;
		img {
			width: 1.5rem;
            object-fit: contain;
		}
		&:hover {
			filter: brightness(1) grayscale(0);
			opacity: 1;
		}
	}
}

.posts {
    article.post {
        display: flex;
        flex-direction: column;
        text-align: left;
    }

    article.post small {
        color: lightgray;
        font-weight: bold;
        margin-bottom: 0.5rem;
    }
    
    article.post:not(:last-child) {
        border-bottom: medium dashed white;
        margin-bottom: 2rem;
        padding-bottom: 2rem;
    }
}

section > article.post {
    text-align: left;

    p {
        margin: 1rem 0;
    }
}

footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 1rem 0;
}


@media screen and (max-width: 1280px) {
    #navbar .nav-center a.logo-wrapper {
        .title {
            font-size: 2.25rem;
        }

        .logo {
            width: 4rem;
            height: 4rem;
        }
    }
}


@media screen and (max-width: 980px) {
    #navbar .nav-center {
        flex-direction: column !important;
    }

    #navbar .nav-links {
        margin: 0;
        height: auto;
        margin-top: 4rem;
    }
}

@media screen and (max-width: 580px) {
    #navbar .nav-center a.logo-wrapper {
        .title {
            font-size: 2.25rem;
        }

        .logo {
            width: 3.5rem;
            height: 3.5rem;
        }
    }

    #navbar .nav-links {
        flex-direction: column;
    }

    #navbar {
        height: 25rem;
    }
    
    #navbar .nav-links a {
        padding: 0.5em 1em;
    }
}
