@keyframes bounce-in {
    0% {
        transform: scale(1);
    }

    10% {
        transform: scale(1.05);
    }

    30% {
        transform: scale(.9);
    }

    100% {
        transform: scale(1);
    }
}