/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #c9c9c9 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 16px;
    height: 8px;
}

*::-webkit-scrollbar-track {
    background: rgba(255,255,255,0.1);
}

*::-webkit-scrollbar-thumb {
    background-color: #c9c9c9;
    border-radius: 0px;
    border: 0px solid #ffffff;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #efefef;
}
